import React from "react";
import "./UpperMiddle.css";
import { motion } from "framer-motion";
import SearchBar from "../SearchBar/SearchBar";
import { headlineWords, subHeadline } from "../../Constants/ContentConstants";

const UpperMiddle = () => {
  const headline = headlineWords.split(" ");

  return (
    <div className="flex flex-col items-center justify-center h-[90vh] text-white space-y-6 p-4">
      {/* Headline */}
      <motion.h1
        className="flex flex-wrap justify-center text-center mb-4"
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          type: "spring",
          stiffness: 100,
          damping: 10,
          delay: 0.3,
        }}
      >
        {headline.map((word, index) => (
          <motion.span
            key={index}
            initial={{ y: -100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              type: "spring",
              stiffness: 100,
              damping: 10,
              delay: index * 0.15,
            }}
            className="font-bold mx-3 text-xl sm:text-2xl md:text-4xl lg:text-6xl xl:text-7xl"
          >
            {word}
          </motion.span>
        ))}
      </motion.h1>

      {/* Subheadline */}
      <motion.p
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          type: "spring",
          stiffness: 100,
          damping: 10,
          delay: 0.7,
        }}
        className="text-center max-w-screen-lg text-sm sm:text-base md:text-lg px-8 lg:text-xl xl:text-2xl font-light text-gray-300 mx-3 mt-5 gradient-headline"
      >
        {subHeadline}
      </motion.p>
      <div>
        <SearchBar />
      </div>
    </div>
  );
};

export default UpperMiddle;
