import React from "react";
import "./VideoSection.css";
import { videoBaseUrl } from "../../Constants/ContentConstants";

const VideoSection = () => {
  return (
    <div className="relative overflow-hidden">
      {/* Animated blob */}
      <div className="absolute left-0 top-1/3 transform -translate-y-1/2 z-0 ">
        <div className="h-60 w-60 bg-gradient-to-r from-[rgb(47,23,110)] to-[rgb(253,250,255)] rounded-full blur-3xl animated-wrapper "></div>
      </div>

      {/* Video section */}
      <div className="relative z-10 flex justify-center items-start mb-8 mt-8">
        <div className="w-[68%] h-[80vh] bg-gray-400 bg-opacity-[0.07] backdrop-filter backdrop-blur-lg rounded-xl p-6 video-container">
          <iframe
            className="w-full h-full rounded-2xl "
            src={`${videoBaseUrl}?autoplay=1&mute=1&controls=0&loop=1&playlist=oz62S_EX_qQ`}
            title="YouTube video player"
            allow="autoplay; encrypted-media"
            allowFullScreen
          />
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
