import React from "react";
import "../Parallax/Parallax.css"
import Graphs from "../Graphs/Graphs";

const WhyUs = () => {
  return (
    <div className="mt-24">
      {/* <Parallax /> */}
      <Graphs />
    </div>
  );
};

export default WhyUs;
