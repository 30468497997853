import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SuccessModal from "./login/SuccessModal";
import * as api from "./login/Api";
import { FaInstagram } from "react-icons/fa";
import BrandHero from "../assets/Images/brandHero1.png";
import { useAuth } from "../Context/AuthContext";
import { motion } from "framer-motion";

const BrandSignup = () => {
  const navigate = useNavigate();
  const { authStatus } = useAuth();
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showInstagramAuth, setShowInstagramAuth] = useState(false);

  const [brandName, setBrandName] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [instagramHandle, setInstagramHandle] = useState("");
  const [email, setEmail] = useState("");
  const [category, setCategory] = useState("");
  const [summary, setSummary] = useState("");

  const handleLoginSuccess = () => {
    setIsSuccessOpen(true);
    setTimeout(() => {
      setIsSuccessOpen(false);
      navigate("/"); // Navigate to home page or dashboard
    }, 3000);
  };

  const showToast = (message, isError = false) => {
    const toast = document.createElement("div");
    toast.textContent = message;
    toast.className = `fixed bottom-4 right-4 p-4 rounded-md ${
      isError ? "bg-red-500" : "bg-green-500"
    } text-white`;
    document.body.appendChild(toast);
    setTimeout(() => toast.remove(), 3000);
  };

  const validateFields = () => {
    if (brandName.trim().length < 2) {
      showToast("Brand Name must be at least 2 characters", true);
      return false;
    }
    if (contactPerson.trim().length < 2) {
      showToast("Contact Person must be at least 2 characters", true);
      return false;
    }
    if (contactNumber.trim() === "") {
      showToast("Contact Number is required", true);
      return false;
    }
    if (!instagramHandle.startsWith("@") || instagramHandle.length < 2) {
      showToast(
        "Instagram Handle must start with '@' and be at least 2 characters long",
        true
      );
      return false;
    }
    if (!email.includes("@") || email.length < 5) {
      showToast("Email must be a valid email address", true);
      return false;
    }
    if (category.trim() === "") {
      showToast("Category is required", true);
      return false;
    }
    if (summary.trim().length < 10) {
      showToast("Brand Summary must be at least 10 characters", true);
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) return;

    setIsLoading(true);

    try {
      const brandData = {
        brand_name: brandName,
        brand_contact_person: contactPerson,
        brand_contact_number: contactNumber,
        brand_instagram_handle: instagramHandle,
        brand_email: email,
        brand_category: category,
        brand_summary: summary,
      };

      await api.signUpBrand(brandData);
      handleLoginSuccess();
    } catch (error) {
      console.error("Error during signup:", error);
      showToast("An error occurred. Please try again.", true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInstagramHandleChange = (e) => {
    const value = e.target.value;
    setInstagramHandle(value);
    setShowInstagramAuth(value.length > 0);
  };

  useEffect(() => {
    if (authStatus === "success") {
      showToast("Authentication Successful!", false);
    } else if (authStatus === "failed") {
      showToast("Authentication Failed", true);
    }
  }, [authStatus]);

  return (
    <div className="container flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
      {/* Left image section */}
      <div className="w-[30vw] h-[50vh] mx-auto md:ml-12 -mt-16 ">
        <motion.img
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            type: "spring",
            stiffness: 150,
            damping: 12,
            delay: 0.3,
          }}
          className="w-[100%] h-[100%] object-cover overflow-visible "
          src={BrandHero}
          alt=""
        />
      </div>

      {/* form section */}

      <div className="min-h-screen  flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <motion.h2
            initial={{ y: -100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              type: "spring",
              stiffness: 150,
              damping: 10,
              delay: 0.3,
            }}
            className="mt-6 text-center text-4xl uppercase font-extrabold text-white"
          >
            Elevate your <span className="text-purple-400">brand</span>
          </motion.h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <motion.div
            initial={{ y: -100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              type: "spring",
              stiffness: 150,
              damping: 10,
              delay: 0.35,
            }}
            className=" bg-purple-100 py-8 px-4 shadow sm:rounded-lg sm:px-10"
          >
            <form className="space-y-4" onSubmit={handleSubmit}>
              <motion.div
                initial={{ y: -100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 150,
                  damping: 10,
                  delay: 0.35,
                }}
              >
                <label
                  htmlFor="brandName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Brand Name
                </label>
                <div className="mt-1">
                  <input
                    id="brandName"
                    name="brandName"
                    type="text"
                    placeholder="Ex. Blinkit, Nike"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={brandName}
                    onChange={(e) => setBrandName(e.target.value)}
                  />
                </div>
              </motion.div>

              <motion.div
                initial={{ y: -100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 150,
                  damping: 10,
                  delay: 0.3,
                }}
              >
                <label
                  htmlFor="contactPerson"
                  className="block text-sm font-medium text-gray-700"
                >
                  Contact Person
                </label>
                <div className="mt-1">
                  <input
                    id="contactPerson"
                    name="contactPerson"
                    type="text"
                    placeholder="Ex. Moe carter"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={contactPerson}
                    onChange={(e) => setContactPerson(e.target.value)}
                  />
                </div>
              </motion.div>

              <motion.div
                initial={{ y: -100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 150,
                  damping: 10,
                  delay: 0.33,
                }}
              >
                <label
                  htmlFor="contactNumber"
                  className="block text-sm font-medium text-gray-700"
                >
                  Contact Number
                </label>
                <div className="mt-1">
                  <input
                    id="contactNumber"
                    name="contactNumber"
                    type="tel"
                    placeholder="+1(123) 456-7890"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={contactNumber}
                    onChange={(e) => setContactNumber(e.target.value)}
                  />
                </div>
              </motion.div>

              <motion.div
                initial={{ y: -100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 150,
                  damping: 10,
                  delay: 0.36,
                }}
              >
                <label
                  htmlFor="instagramHandle"
                  className="block text-sm font-medium text-gray-700"
                >
                  Instagram Handle
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    id="instagramHandle"
                    name="instagramHandle"
                    type="text"
                    placeholder="Ex. @Example123"
                    required
                    className="appearance-none block w-full  px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={instagramHandle}
                    onChange={handleInstagramHandleChange}
                  />
                </div>
                {showInstagramAuth && (
                  <div className="mt-2">
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 text-sm font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-[#f9ce34] via-[#ee2a7b] to-[#6228d7]  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#ee2a7b]"
                      onClick={() =>
                        window.open(
                          "https://www.instagram.com/oauth/authorize/third_party?client_id=486392823983950&redirect_uri=https%3A%2F%2Fsavante.xyz%2Fauth%2Finstagram%2Fstatus%2F&scope=user_profile%2Cuser_media&response_type=code",
                          "_blank"
                        )
                      }
                    >
                      <FaInstagram className="mr-2 text-2xl" />
                      Authorize Instagram
                    </button>
                  </div>
                )}
              </motion.div>

              <motion.div
                initial={{ y: -100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 150,
                  damping: 10,
                  delay: 0.39,
                }}
              >
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Ex. Moe@example.com"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </motion.div>

              <motion.div
                initial={{ y: -100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 150,
                  damping: 10,
                  delay: 0.42,
                }}
              >
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-700"
                >
                  Category
                </label>
                <div className="mt-1">
                  <input
                    id="category"
                    name="category"
                    type="text"
                    placeholder="Ex. Health, Fahion, etc."
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  />
                </div>
              </motion.div>

              <motion.div
                initial={{ y: -100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 150,
                  damping: 10,
                  delay: 0.45,
                }}
              >
                <label
                  htmlFor="summary"
                  className="block text-sm font-medium text-gray-700"
                >
                  Brand Summary
                </label>
                <div className="mt-1">
                  <textarea
                    id="summary"
                    name="summary"
                    rows="7"
                    placeholder="Ex. Our brand, Acme Fashion, is dedicated to creating stylish, sustainable clothing for modern women. We believe in eco-friendly practices, ensuring that our products not only look good but also do good. Our core values include sustainability and transparency."
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={summary}
                    onChange={(e) => setSummary(e.target.value)}
                  ></textarea>
                </div>
              </motion.div>

              <motion.div
                initial={{ y: -100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 150,
                  damping: 10,
                  delay: 0.48,
                }}
              >
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  disabled={isLoading}
                >
                  {isLoading ? "Submitting..." : "Sign Up"}
                </button>
              </motion.div>
            </form>

            
          </motion.div>
        </div>

        <SuccessModal
          isOpen={isSuccessOpen}
          onClose={() => setIsSuccessOpen(false)}
        />
      </div>

      {/* right img section */}
      <motion.div
        initial={{ x: 100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{
          type: "spring",
          stiffness: 150,
          damping: 12,
          delay: 0.45,
        }}
        className="w-[30vw] h-[50vh] relative mx-auto md:ml-12 -mb-[24px] "
      >
        <motion.div
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            type: "spring",
            stiffness: 150,
            damping: 12,
            delay: 0.45,
          }}
          className="w-full font-bold relative h-full -ml-6 text-neutral-50 leading-relaxed text-2xl"
        >
          Register your brand with audience demographics and gender preferences
          to receive monthly creator suggestions tailored to your needs.
        </motion.div>
        <motion.div
          animate={{
            scale: [1, 1.25, 1.25, 1, 1],
            rotate: [0, 180, 0, -180, 0],
            borderRadius: ["15%", "50%", "15%", "50%", "15%"],
          }}
          transition={{
            duration: 3,
            ease: "easeInOut",
            times: [0, 0.2, 0.5, 0.8, 1],
            repeat: Infinity,
            repeatDelay: 1,
          }}
          className="absolute -top-12 rounded-full right-34 w-[270px] h-[270px]
                    bg-purple-400/35  "
        ></motion.div>
      </motion.div>
    </div>
  );
};

export default BrandSignup;
