import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaPhone,
  FaTwitter,
} from "react-icons/fa";
import { FaMapLocation } from "react-icons/fa6";
import { motion } from "framer-motion";

const Footer = () => {
  return (
    <div
      className=" gradient-bg
    pt-12 pb-8 text-gray-900 overflow-hidden"
    >
      <div className=" ">
        <div
          className="grid grid-cols-1 sm:grid-cols-2 
            md:grid-cols-3 gap-12  ml-16"
        >
          {/* Company detals */}
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{
              type: "spring",
              stiffness: 150,
              damping: 10,
              delay: 0.2,
              duration: 0.6,
            }}
            className="space-y-6 "
          >
            <h1 className="text-4xl font-bold uppercase ">Savante</h1>
            <p className="text-md max-w-[300px] ">
              Revolutionize your marketing strategy with AI-powered influencer
              collaborations. Discover the future of brand-creator
              partnerships
            </p>
            <div>
              <p className="flex items-center gap-2 ">
                <FaPhone /> (+91)-123456789
              </p>
              <p className="flex items-center gap-2 mt-2 ">
                {" "}
                <FaMapLocation /> Noida, Uttar Pradesh
              </p>
            </div>
          </motion.div>
          {/* Footer links */}
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{
              type: "spring",
              stiffness: 150,
              damping: 10,
              delay: 0.3,
              duration: 0.6,
            }}
            className="space-y-6 ml-16 "
          >
            <h1 className="text-4xl font-bold ">Quick Links</h1>
            <div className="grid grid-cols-1  gap-3">
              {/* First column section */}
              <div>
                <ul className="space-y-3 text-xl ">
                  <li>Home</li>
                  <li>About Us</li>
                  <li>Services</li>
                  <li>Privacy Policy</li>
                </ul>
              </div>
            </div>
          </motion.div>
          {/* socials */}
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{
              type: "spring",
              stiffness: 150,
              damping: 10,
              delay: 0.4,
              duration: 0.6,
            }}
            className="space-y-6 "
          >
            <h1 className="text-4xl  font-bold">Follow Us</h1>

            <div className="flex items-center gap-3">
              <FaFacebook
                className="text-5xl
                        hover:scale-110 duration-300"
              />
              <FaInstagram
                className="text-5xl
                        hover:scale-110 duration-300"
              />
              <FaLinkedin
                className="text-5xl
                        hover:scale-110 duration-300"
              />
              <FaTwitter
                className="text-5xl
                        hover:scale-110 duration-300"
              />
            </div>
          </motion.div>
        </div>
        {/* Copyright */}
        <p className="text-gray-900 text-center mt-8 pt-8 border-t-2 border-gray-700">
          Copyright &copy; 2024 Savante. All rights reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
