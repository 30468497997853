import React from "react";
import Footer from "../Footer/Footer";
import Discover from "./Discover";
import Header from "../Header";

const BrandPage = () => {
  return (
    <div className="overflow-x-hidden antialiased  ">
      <div className="fixed top-0 -z-10 h-full w-full ">
        <div
          className="absolute inset-0 -z-10 h-full w-full items-center px-5 py-24
               [background:radial-gradient(125%_125%_at_50%_10%,#000_40%,#63e_100%)]"
        ></div>
      </div>
      <div>
        <Header />
        <Discover />
        <Footer />
      </div>
    </div>
  );
};

export default BrandPage;
