// UpperMidddle.js constants

export const headlineWords = "Generative AI for Your Brands and Creators";
export const subHeadline ="Reimagine the way we do branding and creator reach out, try out our State of the Art Solution for finding creators you always wanted but couldn't";

// SearchBar.js constants

export const MemoSentences = [
  "Creators with blonde hair Having an engagement rate more than 3% ...",
  "Creators who promote watches with an engagement rate more than 5%...",
  "Creators from Delhi with an engagement rate of greater than 4%...",
];

// VideoSection.js constants

export const videoBaseUrl = "https://www.youtube.com/embed/oz62S_EX_qQ";

