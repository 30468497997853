// App.js
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Hero from "./components/Hero";
import SuccessPage from "./components/SuccessPage";
import CreatorPage from "./components/CreatorPage";
import BrandPage from "./components/BrandPage";
import DiscoverPage from "./components/Discover/DiscoverPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Hero />} />
        <Route path="/discover" element={<DiscoverPage />} />
        <Route path="/auth/instagram/status/" element={<SuccessPage />} />
        <Route path="/creator-signup" element={<CreatorPage />} />
        <Route path="/brand-signup" element={<BrandPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;