import React from "react";
import Header from "./Header";
import UpperMiddle from "./UpperMiddle/UpperMiddle";
import VideoSection from "./VideoSection/VideoSection";
import WhyUs from "./WhyUs/WhyUs";
import WhatWeOffer from "./WhatWeOffer/WhatWeOffer";
import Footer from "./Footer/Footer";
const Hero = () => {
  return (
    <div className="overflow-x-hidden antialiased  ">
      <div className="fixed top-0 -z-10 h-full w-full ">
        <div
          className="absolute inset-0 -z-10 h-full w-full items-center px-5 py-24
               [background:radial-gradient(125%_125%_at_50%_10%,#000_40%,#63e_100%)]"
        ></div>
      </div>
      <div>
        <Header />
        <UpperMiddle />
        <VideoSection />
        <WhyUs />
        <WhatWeOffer />
        <Footer />
      </div>
    </div>
  );
};

export default Hero;
