import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import SearchBar from "../SearchBar/SearchBar";
import { FaUser, FaHeart, FaComment } from "react-icons/fa";
import { Pie, Bar, Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import cross from "../../assets/Images/cross.png";
import "./Discover.css";

const apiUrl = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;
const placeholderImage = "https://via.placeholder.com/300x300"; // Placeholder image URL

const Discover = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage] = useState(12);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [failedImageUrls, setFailedImageUrls] = useState(new Set());
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();

  // Handle search requests
  const handleSearch = useCallback(
    async (query) => {
      console.log("Searching for:", query);
      setIsLoading(true);
      setError(null);

      try {
        const response = await axios.post(
          `${apiUrl}/search/search-by-prompt`,
          { prompt: query },
          {
            headers: {
              "x-api-key": apiKey,
              "Content-Type": "application/json",
            },
          }
        );

        setSearchResults(response.data);

        // Update URL with search query
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("query", query);
        navigate(`?${searchParams.toString()}`, { replace: true });
      } catch (error) {
        console.error("Error fetching search results:", error);
        setError("An error occurred while fetching results. Please try again.");
      } finally {
        setIsLoading(false);
      }
    },
    [location.search, navigate]
  );

  // loading quote rendering
  useEffect(() => {
    let timer;
    if (isInitialLoad) {
      timer = setTimeout(() => {
        setIsInitialLoad(false);
      }, 2000); // Show initial quote for 2 seconds
    }
    return () => clearTimeout(timer);
  }, [isInitialLoad]);

  useEffect(() => {
    console.log(`isLoading state changed: ${isLoading}`);
  }, [isLoading]);

  // Clean up on component unmount
  useEffect(() => {
    return () => {
      setFailedImageUrls(new Set());
    };
  }, []);

  // Fetch search results based on query parameter
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get("query");
    if (query) {
      handleSearch(query);
    }
  }, [location.search, handleSearch]);

  // Handle card click event
  const handleCardClick = (result) => {
    if (!result || !result.user || !result.user.username) {
      console.error("Invalid user data:", result);
      return; // Ensure that undefined data doesn't cause issues
    }

    if (selectedUser?.user?.username === result.user.username) {
      // If the same card is clicked, just toggle the drawer
      setDrawerOpen(!drawerOpen);
    } else {
      // If a different card is clicked, close the drawer, update the user, then open the drawer
      setDrawerOpen(false);
      setTimeout(() => {
        setSelectedUser(result);
        setDrawerOpen(true);
      }, 770);
    }
  };

  // Pagination logic: Calculate current page results
  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentResults = searchResults.slice(indexOfFirstResult, indexOfLastResult);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const LoadingQuote = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="flex items-center justify-center h-[65vh]"
    >
      <motion.p
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2, duration: 1.5 }}
        className="text-3xl text-center text-neutral-200 max-w-2xl"
      >
        "The future of marketing is in the hands of creators and brands who
        understand the power of authentic connections."
      </motion.p>
    </motion.div>
  );

  return (
    <div className="flex ">
      {/* -------------------------------------------------------Drawer------------------------------------------------------------- */}
      {/* Drawer Section */}
<div
  className={`transition-all duration-300 ${drawerOpen ? "translate-x-0" : "translate-x-full"} 
    fixed top-0 right-0 h-full border-l-[2px] border-purple-500 cursor-default bg-gray-950 text-white 
    w-[37vw] z-50 overflow-y-auto`}
>
  <button
    onClick={() => setDrawerOpen(false)}
    className="absolute top-4 right-4 h-10 w-10 text-white"
  >
    <img src={cross} alt="" />
  </button>
  <div className="p-4">
    {isLoading && <p>Loading user details...</p>}
    {error && <p className="text-red-400">{error}</p>}
    {!isLoading && !error && selectedUser && selectedUser.user && (
      <>
        {/* User Profile Section */}
        <div className="flex items-center mb-6">
          <img
            src={selectedUser.user.profile_pic_url || placeholderImage}
            alt={`${selectedUser.user.username || "User"}'s profile`}
            className="w-36 h-36 rounded-full mr-4"
          />
          <div>
            <h2 className="text-2xl font-bold">
              @{selectedUser.user.username || "Username not available"}
            </h2>
            <p className="text-md mt-2">
              {selectedUser.user.category_name || "Category not available"}
            </p>
          </div>
        </div>

        {/* Followers, Likes, and Comments */}
        <div className="grid grid-cols-2 gap-4 border-2 border-purple-400 pl-6 py-4 rounded-lg my-6">
          <div>
            <FaUser className="inline mr-2" />
            <span>{selectedUser.user.followers_count || "N/A"} Followers</span>
          </div>
          <div>
            <FaHeart className="inline mr-2" />
            <span>{selectedUser.media_node?.like_count || "N/A"} Likes</span>
          </div>
          <div>
            <FaComment className="inline mr-2" />
            <span>{selectedUser.media_node?.comment_count || "N/A"} Comments</span>
          </div>
        </div>

        {/* Biography Section */}
        {selectedUser.user.biography && (
          <div className="mb-6">
            <h3 className="text-lg text-purple-400 font-semibold mb-2 uppercase">Biography</h3>
            <div className="border-2 border-purple-400 rounded-lg p-4 inline-block">
              <p className="text-sm">{selectedUser.user.biography}</p>
            </div>
          </div>
        )}

        {/* Engagement Rate Section
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2 uppercase text-purple-400">Engagement</h3>
          <p className="text-neutral-100">
            Engagement Rate: {selectedUser.user.engagement_rate || "N/A"}%
          </p>
        </div> */}

        {/* Audience Insights - Gender Pie Chart */}
        {selectedUser.audience_demographics && (
  <div className="-mb-4 h-[70%] w-[80%] pl-12">
    <h3 className="text-lg font-semibold mb-2 uppercase -ml-12 text-purple-400">
      Gender Distribution
    </h3>
    <Pie
      data={{
        labels: ["Male", "Female"],
        datasets: [
          {
            data: [
              selectedUser.audience_demographics["18-24"]?.gender?.male || 0,
              selectedUser.audience_demographics["18-24"]?.gender?.female || 0,
            ],
            backgroundColor: ["#58508d", "#bc5090"],
          },
        ],
      }}
      options={{
        responsive: true,
        plugins: {
          legend: {
            position: "right",
            labels: {
              font: { size: 14 },
              color: "#DCDDDB",
              padding: 20,
            },
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.label || "";
                let value = context.raw || 0;
                return `${label}: ${value}%`;
              },
            },
          },
        },
      }}
    />
  </div>
)}


        {/* Age Distribution Bar Chart */}
        {selectedUser.audience_demographics && (
          <div className="mb-4 h-[45vh]">
            <h3 className="text-lg font-semibold mb-2 uppercase text-purple-400">Age Distribution</h3>
            <Bar
              data={{
                labels: Object.keys(selectedUser.audience_demographics),
                datasets: [
                  {
                    label: "Male",
                    data: Object.values(selectedUser.audience_demographics).map(
                      (ageGroup) => ageGroup.gender?.male || 0
                    ),
                    backgroundColor: "#58508d",
                    stack: "stack1",
                  },
                  {
                    label: "Female",
                    data: Object.values(selectedUser.audience_demographics).map(
                      (ageGroup) => ageGroup.gender?.female || 0
                    ),
                    backgroundColor: "#bc5090",
                    stack: "stack1",
                  },
                ],
              }}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "top",
                    labels: { font: { size: 16 }, color: "#DCDDDB", padding: 18 },
                  },
                  tooltip: {
                    callbacks: {
                      label: (context) => {
                        const label = context.dataset.label || "";
                        const value = context.raw || 0;
                        return `${label}: ${value}%`;
                      },
                    },
                  },
                },
                scales: {
                  x: {
                    stacked: true,
                    ticks: { font: { size: 14 }, color: "#DCDDDB" },
                  },
                  y: {
                    stacked: true,
                    ticks: { font: { size: 14 }, color: "#DCDDDB" },
                  },
                },
              }}
            />
          </div>
        )}

        {/* Audience Geographics - Location Donut Chart */}
        {selectedUser.audience_geographics && (
          <div className="mb-16 h-[45vh] flex flex-col items-center">
            <h3 className="text-lg font-semibold mb-4 uppercase text-purple-400 self-start">
              Top Audience Locations
            </h3>
            <Doughnut
              data={{
                labels: Object.keys(selectedUser.audience_geographics),
                datasets: [
                  {
                    data: Object.values(selectedUser.audience_geographics),
                    backgroundColor: ["#ff7c43", "#f95d6a", "#2f4b7c", "#a05195"],
                  },
                ],
              }}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "bottom",
                    labels: { font: { size: 14 }, color: "#DCDDDB", padding: 15 },
                  },
                  tooltip: {
                    callbacks: {
                      label: (context) => {
                        const city = context.label;
                        const percentage = context.raw;
                        return `${city}: ${percentage}%`;
                      },
                    },
                  },
                },
                cutout: "70%",
              }}
            />
          </div>
        )}
      </>
    )}
  </div>
</div>

 {/* ------------------------------------------------Main content-------------------------------------------------------------- */}
{/* Main Content Section */}
<div
  className={`transition-all duration-300 ease-in-out ${
    drawerOpen ? "w-[calc(100%-37vw)]" : "w-full"
  } p-16`}
>
  <div className="mb-12">
    <SearchBar onSearch={handleSearch} />
  </div>

  <AnimatePresence mode="wait">
    {isInitialLoad ? (
      <LoadingQuote key="initial-loading" />
    ) : isLoading ? (
      <LoadingQuote key="search-loading" />
    ) : error ? (
      <motion.p
        key="error"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="text-red-300"
      >
        {error}
      </motion.p>
    ) : (
      <motion.div
        key="results"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className={`grid gap-6 bg-gray-400 bg-opacity-[0.2] backdrop-filter backdrop-blur-lg border-l-[2px] border-t-[2px] border-l-purple-500 border-t-purple-500 rounded-lg p-6 ${
            drawerOpen ? "md:grid-cols-3 sm:grid-cols-2" : "md:grid-cols-4 sm:grid-cols-2"
          }`}
        >
          {currentResults.map((result, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1, duration: 0.5 }}
              onClick={() => handleCardClick(result)} // Pass the entire result object
              className="relative rounded-lg overflow-hidden shadow-lg cursor-pointer aspect-[4/5]"
            >
              {/* Image container with overlay */}
              <div className="absolute inset-0">
                <img
                  src={result.media_node?.media_url || result.user.profile_pic_url || placeholderImage}
                  alt={`${result.user.username || "Content"}`}
                  className="object-cover w-full h-full"
                  onError={(e) => {
                    const failedUrl = e.target.src;
                    if (!failedImageUrls.has(failedUrl)) {
                      setFailedImageUrls((prev) => new Set(prev).add(failedUrl));
                      e.target.src = result.user.profile_pic_url || placeholderImage;
                    } else {
                      e.target.src = placeholderImage;
                    }
                  }}
                />
                {/* Overlay */}
                <div className="absolute inset-0 bg-black opacity-40" />
              </div>

              {/* Text and info */}
              <div className="absolute inset-0 p-4 flex flex-col justify-between">
                {/* Username */}
                <div className="flex justify-between items-start">
                  <p className="text-white font-bold text-sm sm:text-base">
                    {result.user?.username || "Username not available"}
                  </p>
                  <div className="flex items-center space-x-1">
                    <FaUser className="text-white text-xs sm:text-sm" />
                    <p className="text-white text-xs sm:text-sm">
                      {result.user?.followers_count || "N/A"}
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>

        {/* Pagination */}
        <motion.div
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            type: "spring",
            stiffness: 150,
            damping: 10,
            delay: 0.8,
          }}
          className="flex justify-center mt-8"
        >
          {Array.from(
            { length: Math.ceil(searchResults.length / resultsPerPage) },
            (_, i) => (
              <button
                key={i}
                onClick={() => paginate(i + 1)}
                className={`mx-1 px-3 py-1 rounded ${
                  currentPage === i + 1
                    ? "bg-purple-600 text-white"
                    : "bg-gray-300 text-black"
                }`}
              >
                {i + 1}
              </button>
            )
          )}
        </motion.div>
      </motion.div>
    )}
  </AnimatePresence>
</div>


    </div>
  );
};

export default Discover;
