// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.animated-wrapper {
  animation-name: move-wrapper;
  animation-duration: 20s; /* Duration for smooth movement */
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  position: absolute;
}

@keyframes move-wrapper {
  0% {
    transform: translate(0%, 0%) rotate(0deg) scale(0.8);
  }
  33% {
    transform: translate(40%, -20%) rotate(120deg) scale(1.2);
  }
  66% {
    transform: translate(20%, 40%) rotate(240deg) scale(1);
  }
  100% {
    transform: translate(0%, 0%) rotate(360deg) scale(0.8);
  }
}

.video-container {
  border: 0.5px solid rgb(160, 160, 160); 
  box-shadow: 0 2px 4px rgba(203, 203, 203, 0.3);
}
`, "",{"version":3,"sources":["webpack://./src/components/VideoSection/VideoSection.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,uBAAuB,EAAE,iCAAiC;EAC1D,mCAAmC;EACnC,sCAAsC;EACtC,6BAA6B;EAC7B,kBAAkB;AACpB;;AAEA;EACE;IACE,oDAAoD;EACtD;EACA;IACE,yDAAyD;EAC3D;EACA;IACE,sDAAsD;EACxD;EACA;IACE,sDAAsD;EACxD;AACF;;AAEA;EACE,sCAAsC;EACtC,8CAA8C;AAChD","sourcesContent":[".animated-wrapper {\n  animation-name: move-wrapper;\n  animation-duration: 20s; /* Duration for smooth movement */\n  animation-iteration-count: infinite;\n  animation-timing-function: ease-in-out;\n  animation-fill-mode: forwards;\n  position: absolute;\n}\n\n@keyframes move-wrapper {\n  0% {\n    transform: translate(0%, 0%) rotate(0deg) scale(0.8);\n  }\n  33% {\n    transform: translate(40%, -20%) rotate(120deg) scale(1.2);\n  }\n  66% {\n    transform: translate(20%, 40%) rotate(240deg) scale(1);\n  }\n  100% {\n    transform: translate(0%, 0%) rotate(360deg) scale(0.8);\n  }\n}\n\n.video-container {\n  border: 0.5px solid rgb(160, 160, 160); \n  box-shadow: 0 2px 4px rgba(203, 203, 203, 0.3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
