// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Global Styles */
html {
  scroll-behavior: smooth;
 
}
`, "",{"version":3,"sources":["webpack://./src/components/Graphs/Graphs.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;EACE,uBAAuB;;AAEzB","sourcesContent":["/* Global Styles */\nhtml {\n  scroll-behavior: smooth;\n \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
