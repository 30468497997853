import axios from 'axios';
import { AUTH_TOKEN, AUTH_TOKEN_2 } from '../../Constants/RequestConstants'; // Import the constants

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_KEY,
    'Authentication': AUTH_TOKEN,
    'Authorization': AUTH_TOKEN_2,
  }
});

export const signUpBrand = async (brandData) => {
  try {
    console.log('Sending request to:', `${api.defaults.baseURL}/signup/brands`);
    
    const response = await api.post('/signup/brands', brandData);
    return response.data;
  } catch (error) {
    console.error('Error in brand signup:', error.response || error);
    throw error;
  }
};

export const signUpCreator = async (creatorData) => {
  try {
    console.log('Sending request to:', `${api.defaults.baseURL}/signup/creators`);
   
    const response = await api.post('/signup/creators', creatorData);
    return response.data;
  } catch (error) {
    console.error('Error in creator signup:', error.response || error);
    throw error;
  }
};
