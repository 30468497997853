// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.instagram-gradient {
    background: linear-gradient(to right, #f58529 25%, #dd2a7b 60%, #8134af 80%, #515bd4 100%);
    background-clip: text;  /* Standard property */
    -webkit-background-clip: text;  /* WebKit-specific property */
    -webkit-text-fill-color: transparent;  /* WebKit-specific property */
  }`, "",{"version":3,"sources":["webpack://./src/components/Discover/Discover.css"],"names":[],"mappings":"AAAA;IACI,0FAA0F;IAC1F,qBAAqB,GAAG,sBAAsB;IAC9C,6BAA6B,GAAG,6BAA6B;IAC7D,oCAAoC,GAAG,6BAA6B;EACtE","sourcesContent":[".instagram-gradient {\n    background: linear-gradient(to right, #f58529 25%, #dd2a7b 60%, #8134af 80%, #515bd4 100%);\n    background-clip: text;  /* Standard property */\n    -webkit-background-clip: text;  /* WebKit-specific property */\n    -webkit-text-fill-color: transparent;  /* WebKit-specific property */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
