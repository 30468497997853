import React from "react";
import LogoImg from "../assets/Images/brand-image.png";
import { useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBrandSignupClick = () => {
    navigate("/brand-signup");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleCreatorSignupClick = () => {
    navigate("/creator-signup");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleLogoClick = () => {
    navigate("/");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const isBrandPage = location.pathname === "/brand-signup";
  const isCreatorPage = location.pathname === "/creator-signup";
  const isDiscoverPage = location.pathname === "/discover";

  return (
    <nav
      className={`w-full z-20 top-0 start-0 ${
        isDiscoverPage ? "" : "fixed"
      }`}
      style={{
        background:
          "radial-gradient(29.05% 42.59% at 50% -5.56%, rgba(100, 25, 255, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%)",
        backdropFilter: "blur(10px)",
        WebkitBackdropFilter: "blur(10px)",
      }}
    >
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <motion.div
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            type: "spring",
            stiffness: 100,
            damping: 10,
            delay: 0.25,
          }}
          className="flex items-center space-x-3 rtl:space-x-reverse"
        >
          <div
            className="flex items-center space-x-3 rtl:space-x-reverse cursor-pointer"
            onClick={handleLogoClick}
          >
            <img src={LogoImg} className="h-12" alt="Savante Logo" />
            <span className="self-center text-4xl font-semibold whitespace-nowrap text-white">
              Savante
            </span>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            type: "spring",
            stiffness: 100,
            damping: 10,
            delay: 0.25,
          }}
          className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse"
        >
          {!isBrandPage && (
            <button
              className="cursor-pointer font-semibold overflow-hidden relative z-100 border-2 border-[rgb(184,149,254)] rounded-sm group mx-6 px-8 py-2"
              onClick={handleBrandSignupClick}
            >
              <span className="relative z-10 text-white group-hover:text-white text-xl duration-500">
                I am a Brand
              </span>
              <span className="absolute w-full h-full bg-[rgb(184,149,254)] -left-[7.7rem] top-0 -rotate-45 group-hover:rotate-0 group-hover:left-0 duration-500"></span>
              <span className="absolute w-full h-full bg-[rgb(184,149,254)] -right-[7.7rem] top-0 -rotate-45 group-hover:rotate-0 group-hover:right-0 duration-500"></span>
            </button>
          )}
          {!isCreatorPage && (
            <button
              className="cursor-pointer font-semibold overflow-hidden relative z-100 mx-6 border-2 border-[rgb(184,149,254)] rounded-sm group px-6 py-2"
              onClick={handleCreatorSignupClick}
            >
              <span className="relative z-10 text-white group-hover:text-white text-xl duration-500">
                I am a Creator
              </span>
              <span className="absolute w-full h-full bg-[rgb(184,149,254)] -left-[7.7rem] top-0 -rotate-45 group-hover:rotate-0 group-hover:left-0 duration-500"></span>
              <span className="absolute w-full h-full bg-[rgb(184,149,254)] -right-[7.7rem] top-0 -rotate-45 group-hover:rotate-0 group-hover:right-0 duration-500"></span>
            </button>
          )}
        </motion.div>
      </div>
    </nav>
  );
};

export default Header;
