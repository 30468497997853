import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../Context/AuthContext";

const AUTH_STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  FAILED: "failed",
};

function SuccessPage() {
  const { setAuthStatus } = useAuth();
  const [status, setStatus] = useState(AUTH_STATUS.LOADING);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    const apiUrl = process.env.REACT_APP_API_URL;
    const apiKey = process.env.REACT_APP_API_KEY;

    const authorize = async () => {
      try {

        const response = await axios.post(
          `${apiUrl}/auth/instagram`,
          { code },
          {
            headers: {
              "X-Api-Key": apiKey,
              Authentication: "xyz",
              Authorization: "abc",
            },
          }
        );

        if (response.status === 200 || response.status === 201) {
          setStatus(AUTH_STATUS.SUCCESS);
          setAuthStatus(AUTH_STATUS.SUCCESS);
        } else {
          setStatus(AUTH_STATUS.FAILED);
          setAuthStatus(AUTH_STATUS.FAILED);
        }
      } catch (error) {
        console.error("Error authorizing:", error);
        setStatus(AUTH_STATUS.FAILED);
        setAuthStatus(AUTH_STATUS.FAILED);
      }
    };

    if (code) {
      authorize();
    } else {
      console.log("No Code in URL");
      setStatus(AUTH_STATUS.FAILED);
      setAuthStatus(AUTH_STATUS.FAILED);
    }
  }, [setAuthStatus]);

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      {status === AUTH_STATUS.LOADING && <p>Processing authorization...</p>}
      {status === AUTH_STATUS.SUCCESS && <h1>Authentication Successful!</h1>}
      {status === AUTH_STATUS.FAILED && <h1>Authentication Failed</h1>}
      <p>
        {status === AUTH_STATUS.LOADING
          ? "Please wait while we process your authorization."
          : status === AUTH_STATUS.SUCCESS
          ? "You have been successfully authenticated. You can now proceed with the application."
          : "Please try authorizing again."}
      </p>
    </div>
  );
}

export default SuccessPage;
