// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */
.gradient-headline {
  background: linear-gradient(78deg, rgb(255, 255, 255) 10%, rgba(179, 179, 179, 0.9) 50%,rgb(69,35,160) 90.93%);
  -webkit-background-clip: text; /* For webkit browsers */
  background-clip: text; /* For other browsers */
  -webkit-text-fill-color: transparent; /* For webkit browsers */
}

`, "",{"version":3,"sources":["webpack://./src/components/UpperMiddle/UpperMiddle.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,8GAA8G;EAC9G,6BAA6B,EAAE,wBAAwB;EACvD,qBAAqB,EAAE,uBAAuB;EAC9C,oCAAoC,EAAE,wBAAwB;AAChE","sourcesContent":["/* styles.css */\n.gradient-headline {\n  background: linear-gradient(78deg, rgb(255, 255, 255) 10%, rgba(179, 179, 179, 0.9) 50%,rgb(69,35,160) 90.93%);\n  -webkit-background-clip: text; /* For webkit browsers */\n  background-clip: text; /* For other browsers */\n  -webkit-text-fill-color: transparent; /* For webkit browsers */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
