import React, { useState } from "react";
import "./Graphs.css";
import { motion } from "framer-motion";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Pie, Bar } from "react-chartjs-2";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

const BarChart = () => {
  const data = {
    labels: [
      "Nano Creators",
      "Micro Creators",
      "Macro Creators",
      "Mega Creators",
    ],
    datasets: [
      {
        label: "2023",
        data: [39, 30, 19, 12],
        backgroundColor: "rgba(54, 162, 235, 0.8)",
      },
      {
        label: "2024",
        data: [44, 26, 17, 13],
        backgroundColor: "rgba(153, 102, 255, 0.8)",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          font: {
            weight: "bold", // Make x-axis labels bold
          },
        },
      },
      y: {
        beginAtZero: true,
        max: 50,
        ticks: {
          font: {
            weight: "bold", // Make y-axis labels bold
          },
        },
        title: {
          display: true,
          text: "Percentage (%)",
          font: {
            weight: "bold", // Make y-axis title bold
          },
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          font: {
            weight: "bold", // Make legend text bold
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || "";
            const value = context.parsed.y !== null ? context.parsed.y : "";
            return `${label}: ${value}%`;
          },
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};

const PieChart = () => {
  const data = {
    labels: ["Yes", "Maybe", "No"],
    datasets: [
      {
        data: [63, 27, 10],
        backgroundColor: ["#8bd3c7", "#A372FF", "#57A9EC"],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "right",
        labels: {
          font: {
            weight: "bold", // Make legend text bold
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.label || "";
            const value = context.parsed !== null ? context.parsed : "";
            return `${label}: ${value}%`;
          },
        },
      },
    },
  };

  return <Pie data={data} options={options} />;
};

const BarChart2 = () => {
  const data = {
    labels: ["0-10", "10-50", "50-100", "100-1000", "1000+"],
    datasets: [
      {
        data: [37.6, 19.0, 15.2, 13.5, 14.7],
        backgroundColor: [
          "#77D8C8",
          "#A97CFF",
          "#77D8C8 ",
          "#A97CFF ",
          "#77D8C8",
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          font: {
            weight: "bold", // Make x-axis labels bold
          },
        },
      },
      y: {
        beginAtZero: true,
        max: 40,
        ticks: {
          font: {
            weight: "bold", // Make y-axis labels bold
          },
        },
        title: {
          display: true,
          text: "Percentage (%)",
          font: {
            weight: "bold", // Make y-axis title bold
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          font: {
            weight: "bold", // Make legend text bold if needed
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.parsed.y !== null ? context.parsed.y : "";
            return `${value}%`;
          },
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};

const BarChart3 = () => {
  const data = {
    labels: [
      "Influencer Identification",
      "Locating and distributing relevant content",
      "Use AI to identify Bogus Creators and engagements",
    ],
    datasets: [
      {
        data: [55.8, 18.6, 5.7],
        backgroundColor: ['#77D8C8', '#A97CFF', '#57A9EC'],
        borderColor: ['#77D8C8', '#A97CFF', '#57A9EC'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          autoSkip: false,
          maxRotation: 7,
          minRotation: 0,
          font: {
            size: 13,
            family: "Arial",
          },
          padding: 2,
          align: "center",
        },
      },
      y: {
        beginAtZero: true,
        max: 60,
        title: {
          display: true,
          text: "Percentage (%)",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.parsed.y.toFixed(1)}%`;
          },
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};

const items = [
  {
    id: 1,
    title: (
      <>
        40% of Brands works with{" "}
        <span className="text-purple-600">10 Creators </span>or less
      </>
    ),
    desc: "Limited collaboration with a small group of Creators can lead to missed opportunities and insufficient reach. Brands may struggle to diversify their audience and fail to leverage the full potential of creator marketing.",
  },
  {
    id: 2,
    title: (
      <>
        <span className="text-purple-600">More Than 60%</span> Plan to Use AI or
        ML in Their Creator Campaigns
      </>
    ),
    desc: "Brands recognizing the need for advanced tools to enhance targeting and effectiveness. Our AI-driven solution aligns perfectly with this trend, offering cutting-edge capabilities to optimize campaign performance and maximize ROI.",
  },
  {
    id: 3,
    title: (
      <>
        <span className="text-purple-600">55.8% of Brands</span> want an AI-ML
        tool to identify creators
      </>
    ),
    desc: "Highlighting the demand for precision in creator selection. Our AI-powered solution addresses this need, delivering accurate, data-driven insights to help brands connect with the perfect creators for their campaigns.",
  },
  {
    id: 4,
    title: (
      <>
        Strong Preference for{" "}
        <span className="text-purple-600">Smaller creators</span>
      </>
    ),
    desc: "Brands are increasingly favoring smaller creators for their authenticity and engagement. Our platform caters to this shift, offering targeted solutions that connect brands with niche creators who deliver more meaningful and personalized interactions.",
  },
];

const Single = ({ item }) => {
  return (
    <div className="flex-shrink-0 w-screen h-screen flex items-center justify-center p-4">
      <div className="bg-purple-200 rounded-lg shadow-lg overflow-hidden w-full max-w-6xl h-[70vh]">
        <div className="flex flex-col md:flex-row h-full">
          <div className="md:w-[57%] rounded-lg h-full p-4">
            {item.id === 1 && <BarChart2 />}
            {item.id === 2 && <PieChart />}
            {item.id === 3 && <BarChart3 />}
            {item.id === 4 && <BarChart />}
          </div>
          <div className="md:w-[43%] p-8 flex flex-col items-center overflow-y-auto">
            <h2 className="text-4xl font-bold mt-16 mb-6">{item.title}</h2>
            <p className="text-gray-800">{item.desc}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Graphs = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="relative min-h-screen pt-16 pb-24">
      <div className="container mx-auto px-4">
        <motion.h2
          initial={{ y: -100, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          
          transition={{
            type: "spring",
            stiffness: 150,
            damping: 10,
            delay: 0.5,
          }}
          className="text-5xl font-bold uppercase text-center gradient-text flex items-center justify-center"
        >
          Opportunities in Creator Market
        </motion.h2>
      </div>

      <div className="relative h-[80vh]  mt-16">
        <motion.div
          animate={{ x: `-${currentIndex * 100}%` }}
          transition={{ type: "spring", stiffness: 250, damping: 20 }}
          className="flex h-full"
        >
          {items.map((item, index) => (
            <Single key={item.id} item={item} />
          ))}
        </motion.div>

        <button
          onClick={prevSlide}
          className="absolute top-[57%] left-4 transform -translate-y-1/2 bg-purple-200 bg-opacity-50 hover:bg-opacity-75 rounded-full p-2 focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>

        <button
          onClick={nextSlide}
          className="absolute top-[57%] right-4 transform -translate-y-1/2 bg-purple-200  bg-opacity-50 hover:bg-opacity-75 rounded-full p-2 focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>

        <div className="absolute -bottom-7 left-[56%] transform -translate-x-1/2 flex space-x-2">
          {items.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`w-3 h-3 rounded-full ${
                currentIndex === index ? "bg-gray-700" : "bg-purple-400"
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Graphs;