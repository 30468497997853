import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import btnIcon from "../../assets/Images/btnIcon1.gif";
import { motion } from "framer-motion";
import { MemoSentences } from "../../Constants/ContentConstants";

const SearchBar = ({ onSearch }) => {
  const sentences = useMemo(
    () => MemoSentences,
    []
  );

  const [placeholder, setPlaceholder] = useState("");
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const [currentCharIndex, setCurrentCharIndex] = useState(0);
  const [deleting, setDeleting] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isWaiting, setIsWaiting] = useState(false);
  const inputRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleTyping = () => {
      if (isFocused || inputValue) return;

      const currentSentence = sentences[currentSentenceIndex];

      if (!deleting && currentCharIndex < currentSentence.length) {
        setPlaceholder((prev) => prev + currentSentence[currentCharIndex]);
        setCurrentCharIndex((prev) => prev + 1);
      } else if (!deleting && currentCharIndex === currentSentence.length) {
        setIsWaiting(true);
        setTimeout(() => {
          setIsWaiting(false);
          setDeleting(true);
        }, 1000); // 1 second delay
      } else if (deleting && currentCharIndex > 0) {
        setPlaceholder((prev) => prev.slice(0, -1));
        setCurrentCharIndex((prev) => prev - 1);
      } else if (deleting && currentCharIndex === 0) {
        setDeleting(false);
        setCurrentSentenceIndex((prev) => (prev + 1) % sentences.length);
      }
    };

    if (!isWaiting) {
      const typingInterval = setTimeout(handleTyping, deleting ? 40 : 50);
      return () => clearTimeout(typingInterval);
    }
  }, [
    currentCharIndex,
    deleting,
    currentSentenceIndex,
    isFocused,
    inputValue,
    sentences,
    isWaiting,
  ]);

  const startAnimation = () => {
    setPlaceholder("");
    setCurrentCharIndex(0);
    setDeleting(false);
    setCurrentSentenceIndex(0);
  };

  const handleSearch = () => {
    if (inputValue.trim()) {
      if (location.pathname === "/discover") {
        // If already on discover page, just update the search
        onSearch(inputValue);
      } else {
        // If not on discover page, navigate to it with the search query
        navigate(`/discover?query=${encodeURIComponent(inputValue)}`);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
    if (inputValue) {
      setPlaceholder("");
    } else {
      startAnimation();
    }
  };

  const handleBlur = () => {
    if (!inputValue) {
      setIsFocused(false);
    }
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (e.target.value) {
      setPlaceholder("");
    } else {
      if (!isFocused) {
        startAnimation();
      }
    }
  };

  return (
    <motion.div
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        type: "spring",
        stiffness: 150,
        damping: 10,
        delay: 0.8,
      }}
      className="flex items-center mt-16 w-[55vw] mx-auto"
    >
      <input
        ref={inputRef}
        type="text"
        placeholder={placeholder}
        className="w-full p-2 border h-12 pr-5  rounded-l-lg text-black"
        value={inputValue}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
      />
      <button
        onClick={handleSearch}
        type="button"
        className="text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:ring- focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-purple-800/80 font-medium rounded-lg text-lg text-md h-12 w-[14.75rem] px-5 py-2.5 text-center flex items-center justify-center me-2 ml-[-0.7rem]"
      >
        <img src={btnIcon} alt="Icon" className="w-8 h-8 mr-4 scale-125 " />
        Generate
      </button>
    </motion.div>
  );
};
export default SearchBar;
