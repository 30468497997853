import React from "react";
import { motion } from "framer-motion";

const cardOneVariants = {
  hidden: { opacity: 0, y: 100 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 150,
      damping: 10,
      delay: 0.1,
    },
  },
};

const cardTwoVariants = {
  hidden: { opacity: 0, y: 100 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 150,
      damping: 10,
      delay: 0.2,
    },
  },
};

const cardThreeVariants = {
  hidden: { opacity: 0, y: 100 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 150,
      damping: 10,
      delay: 0.3,
    },
  },
};

const cardFourVariants = {
  hidden: { opacity: 0, y: 100 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 150,
      damping: 10,
      delay: 0.4,
    },
  },
};

const WhatWeOffer = () => {
  return (
    <div className="min-h-screen text-white flex flex-col items-center justify-center p-8 space-y-12 overflow-hidden">
      {/* Heading */}
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{
          type: "spring",
          stiffness: 150,
          damping: 10,
          delay: 0.2,
        }}
        className="mb-12 top-0"
      >
        <h2 className="text-6xl font-bold mb-6 gradient-text">
          What We Offer
        </h2>
      </motion.div>
      {/* Cards */}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 w-full max-w-7xl align-baseline cursor-default ">
        <motion.div
          variants={cardOneVariants}
          initial="hidden"
          whileInView="visible"
          className="bg-gray-100 border-2 border-purple-400 hover:bg-opacity-[0.2] bg-opacity-[0.07] backdrop-filter backdrop-blur-lg rounded-lg p-4 h-96 flex flex-col justify-center shadow-md transition-opacity duration-450 ease-in-out"
        >
          <h3 className="text-2xl -pt-6 font-semibold mb-4 ">
            AI-Powered Perfect Matches
          </h3>
          <ul className="bg-purple-200 text-neutral-800 rounded-lg p-4 space-y-3  text-md">
            <li>Advanced AI matching algorithms.</li>
            <li>Analyzes brand goals and audience.</li>
            <li>Recommends ideal Creators.</li>
            <li>Boosts campaign effectiveness.</li>
          </ul>
        </motion.div>

        <motion.div
          variants={cardTwoVariants}
          initial="hidden"
          whileInView="visible"
          className="bg-gray-100 border-2 border-purple-400 hover:bg-opacity-[0.2] bg-opacity-[0.07] backdrop-filter backdrop-blur-lg rounded-lg p-4 h-96 flex flex-col justify-center shadow-md transition-opacity duration-450 ease-in-out"
        >
          <h3 className="text-2xl font-semibold mb-4 ">
            Live Campaign Intelligence
          </h3>
          <ul className="bg-purple-200 text-neutral-800 rounded-lg p-6 px-4 space-y-2 text-md">
            <li>Real-time data tracking.</li>
            <li>Measure Return on Influencer Investment(ROII) effectively.</li>
            <li>Insights on Creator performance.</li>
            <li>Optimize campaigns on the fly for better ROII.</li>
            
          </ul>
        </motion.div>

        <motion.div
          variants={cardThreeVariants}
          initial="hidden"
          whileInView="visible"
          className="bg-gray-100 border-2 border-purple-400 hover:bg-opacity-[0.2] bg-opacity-[0.07] backdrop-filter backdrop-blur-lg rounded-lg p-4 h-96 flex flex-col justify-center shadow-md transition-opacity duration-450 ease-in-out"
        >
          <h3 className="text-2xl font-semibold mb-4 ">
            Discover Diverse Voices
          </h3>
          <ul className="bg-purple-200 text-neutral-800 rounded-lg p-6 space-y-3 text-md">
            <li>Filters for diversity metrics.</li>
            <li>Discover Creators by ethnicity.</li>
            <li>Align with DEI goals.</li>
            <li>Promote inclusivity in campaigns.</li>
          </ul>
        </motion.div>

        <motion.div
          variants={cardFourVariants}
          initial="hidden"
          whileInView="visible"
          className="bg-gray-100 border-2 border-purple-400 hover:bg-opacity-[0.2] bg-opacity-[0.07] backdrop-filter backdrop-blur-lg rounded-lg px-4 h-96 flex flex-col justify-center shadow-md transition-opacity duration-450 ease-in-out"
        >
          <h3 className="text-2xl font-semibold mb-4 ">
            Secure & Authentic Partnerships
          </h3>
          <ul className="bg-purple-200 text-neutral-800 rounded-lg p-6 pb-10 pt-8 space-y-3 text-md ">
            <li>Advanced fraud detection.</li>
            <li>Identifies fake followers.</li>
            <li>Ensures authenticity.</li>
            <li>Builds trust in partnerships.</li>
          </ul>
        </motion.div>
      </div>
    </div>
  );
};

export default WhatWeOffer;
