import React from 'react';
import './SuccessModal.css'; // Make sure to create or update this CSS file
import'./LoginModal.css';

const SuccessModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="success-modal-overlay" onClick={onClose}>
            <div className="success-modal-content" onClick={e => e.stopPropagation()}>
                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle className="checkmark-circle" cx="26" cy="26" r="25" fill="none"/>
                    <path className="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                </svg>
                <div className="thank-you-message">
                    Thank you for signing up. You are in our waitlist, <br/> we will get back to you soon!
                </div>
            </div>
        </div>
    );
};

export default SuccessModal;
